@import "./utilities/fonts";
@import "./utilities/animations";

html {
  width: 100%;
  height: 100%;
}
#root {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  background: #252934;
  font-family: "Raleway";
  overflow-y: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
