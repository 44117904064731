@font-face {
  font-family: "Raleway";
  src: url("./fonts/raleway/Raleway-Regular.ttf");
}
@font-face {
  font-family: "Raleway-semibold";
  src: url("./fonts/raleway/Raleway-SemiBold.ttf");
}
@font-face {
  font-family: "Raleway-bold";
  src: url("./fonts/raleway/Raleway-Bold.ttf");
}
