footer {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  .icon-wrapper {
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
  }

  .social-media-icon {
    cursor: pointer;
    // opacity: 0;
    // animation: popIn 2s forwards;
    transition: all 0.6s ease;
    margin-right: 15%;
    &:hover {
      transition: all 0.5s ease;
    }
  }

  .social-media-icon:hover {
    transform: scale(1.2) !important;
  }

  .delay-1 {
    animation-delay: 1s;
  }

  .delay-2 {
    animation-delay: 2s;
  }
  .name {
    color: #8F9AA7;
    font-size: 10pt;
    margin-top: 1%;
    opacity: 0.7;
  }
}
