.about {
  background: #fff;
  // height: 90vh;
  color: #616161;
  display: flex;
  flex-flow: column;

  .content {
    display: flex;
    flex-flow: column;
    align-items: center;

    .description {
      margin-top: 5%;
      font-size: 12pt;
      line-height: 20pt;
      width: 80%;
    }

    @media (min-width: 600px) {
      .para-1 {
        opacity: 0;
        animation: slideInLeft 0.5s forwards;
        animation-delay: 1s;
      }

      .para-2 {
        opacity: 0;
        animation: slideInRight 0.5s forwards;
        animation-delay: 1.5s;
      }

      .description {
        margin-top: 2%;
        font-size: 14pt;
        line-height: 23pt;
        width: 50%;
      }
    }
  }

  .skills {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    .skills-inner {
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      .skill-icon {
        margin-top: 5%;
        width: 30%;
      }
    }
  }
  @media (min-width: 600px) {
    .skills {
      opacity: 0;
      animation: popIn 0.5s forwards;
      animation-delay: 2s;
    }
    .skills-inner {
      width: 80%;
      .skill-icon {
        margin-top: 5%;
      }
    }
  }
}

.spacer {
  flex: 1 1 auto;
}

svg {
  display: block;
}

@media (min-width: 600px) {
  .about {
    // min-height: 120vh;
    display: block;
  }

  .spacer {
    flex: unset;
    display: none;
  }
}
