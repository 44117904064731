.App {
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 3rem;
  color: white;
}

.content {
  background: white;
  width: 100%;
  // height: 5000px;
}

.fade-in {
  animation: fadeIn 2s forwards;
}

.slide-in-right {
  animation: slideInRight 2s;
}

.delay-1 {
  animation-delay: 1s;
}

.delay-2 {
  animation-delay: 2s;
}

// .section {
//   padding: 0px 125px 50px;
// }
// @media (min-width: 600px) {
//   .section {
//     padding: 0px 250px 100px;
//   }
// }
