.page-title {
  text-transform: uppercase;
  display: flex;
  flex-flow: column;
  align-items: center;

  color: #444649;

  .title {
    margin-top: 5%;
    margin-bottom: 2%;
    font-family: "raleway-bold";
    font-size: 25pt;
  }
  @media (min-width: 600px) {
    .title {
      font-size: 30pt;
      margin-bottom: 0.5%;
    }
  }

  .divider {
    width: 75px;
    height: 3px;
    background: #444649;
  }
}

@media (min-width: 600px) {
  .page-title {
    animation: slideInRight .5s;
    .divider {
      opacity: 0;
      animation: slideInRight .5s forwards;
      animation-delay: .5s;
    }
  }
}
