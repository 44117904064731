.theme-editor {
  display: flex;
  flex-flow: column;
  height: 470px;
  // min-height: 100vh;
  background: #f5f5f5;
  color: #616161;

  @media (min-width: 600px) {
    height: 550px;
  }

  .selector-container {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: center;
  }

  .picker-container {
    display: flex;
    align-items: center;
    margin-top: 5%;
    justify-content: center;
    flex-flow: wrap;
    min-height: 200px;

    .description {
      font-size: 12pt;
      margin-bottom: 5%;
      line-height: 20pt;
    }
    @media (min-width: 600px) {
      .description {
        font-size: 14pt;
        margin-right: 5%;
        margin-bottom: 0;
        line-height: 23pt;
      }
    }
  }

  @media (min-width: 850px) {
    .picker-container {
      flex-flow: row;
      opacity: 0;
      animation: popIn .5s forwards;
      animation-delay: 2.5s;
      margin-top: 2.5%;
    }
  }
}

.attribute-selector {
  font-family: "raleway-bold";
  margin-top: 3%;
  font-size: 10pt;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
  border: solid 2px black;
  padding: 5px;
  z-index: 1;
  transition: all 0.5s ease;
  cursor: pointer;
  margin-right: 1%;
}

@media (min-width: 600px) {
  .attribute-selector {
    opacity: 0;
    animation: flipInX .5s forwards;
    padding: 10px;
    font-size: 10pt;
    width: 100px;
  }
}

.attribute-selector:last-child {
  margin-right: 0;
}
