.nav-bar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: flex-end;

  &.fixed {
    position: fixed !important;
    top: 0;
  }

  .item:last-child {
    margin-right: 2%;
  }

  .nav-selector {
    font-size: 12pt;
    text-transform: uppercase;
    margin-left: 0;
    margin: 1%;
    pointer-events: all;
    cursor: pointer;
    transition: color 0.5s ease;

    &:hover {
      transition: color 0.5s ease;
    }
  }

  .list-toggle {
    pointer-events: all;
    transition: transform 0.3s ease;
    // transition: color 0.5s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
    &.rotate-90 {
      transform: rotate(90deg);
      // transition: transform 1s ease;
    }
  }

  .mobile-list-view {
    max-width: 1200px;
    width: 100%;
    position: absolute;
    top: 53px;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-out;

    &.show {
      height: 220px;
    }

    .item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 12px 20px;
    }

    .item:first-child {
      padding: 25px 20px 12px 20px;
    }
  }
}
.hide {
  display: none;
}
@media (min-width: 600px) {
  .nav-bar {
    justify-content: start;
    .item:first-child {
      margin-left: 25%;
    }
  }
  .fixed {
    animation: popDown 0.5s;
  }
}
