@import "../utilities/animations";

.header {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  z-index: 0;

  .particles-canvas {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    transform: scale(1);
    opacity: 0;
    animation: appear 1s 1;
    animation-fill-mode: forwards;
  }

  .hidden {
    opacity: 0;
  }

  .text {
    font-size: 16pt;
  }

  @media (min-width: 600px) {
    .text {
      font-size: 32pt;
    }
  }

  .highlight {
    font-weight: bold;
    font-family: Raleway-bold;
  }
}

.more-button-wrapper {
  margin-top: 3%;
  font-size: 8pt;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
  border: solid 2px white;
  padding: 10px;
  z-index: 1;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    // transition: all 1s ease;
    .more-button {
      transform: rotate(90deg);
    }
  }

  .more-button {
    transition: all 0.5s ease;
  }
}

@media (min-width: 600px) {
  .more-button-wrapper {
    padding: 15px;
    font-size: 12pt;
    width: 150px;
    opacity: 0;
    animation: popIn 0.5s ease forwards;
    animation-delay: 2.8s;
  }

  .hello {
    opacity: 0;
    animation: fadeIn 1s forwards;
  }

  .end-of-first-line {
    opacity: 0;
    animation: fadeIn 0.5s forwards 1s;
  }

  .second-line {
    opacity: 0;
    animation: fadeIn 0.5s forwards 2s;
  }
}
